<template>
    <v-container grid-list-md pa-0 relative>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="primary--border" outlined>
            <v-card-title class="title">
              Video Management
              <v-spacer></v-spacer>
              <add-button
                permission="notice-read"
                @action="
                  (form.dialog = true), $refs.form ? $refs.form.reset() : ''
                "
              >
                Add
              </add-button>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="form.items.data"
              :options.sync="pagination"
              :footer-props="footerProps"
              :loading="form.loading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ index + form.items.meta.from }}</td>
                  <td class="text-xs-left">
                    <v-icon small>open_in_new</v-icon> &nbsp;
                    <a
                      target="_blank"
                      :href="'https://youtube.com/watch?v=' + item.video_link"
                      >{{ item.video_link }}</a
                    >
                  </td>
                  <td class="text-xs-left">
                    {{ item.description || "-" }}
                  </td>
                  <td class="text-right">
                    <edit-button
                      color="warning"
                      icon="edit"
                      @agree="form.reset(), form.edit(item)"
                      permission="notice-read"
                    >
                    </edit-button>
                    <delete-button
                      color="danger"
                      @agree="form.reset(), form.delete(item.id)"
                      permission="notice-read"
                    >
                    </delete-button>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog v-model="form.dialog" persistent max-width="550px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
            
              <v-text-field
                autocomplete="video_link"
                required
                label="Video Link *"
                class="pa-0"
                outlined
                dense
                v-model="form.video_link"
                name="video_link"
                :rules="[(v) => !!v || 'Video Link is required']"
              />
            
              <v-textarea
                autocomplete="videoLink"
                label="Description"
                class="pa-0 mt-5"
                outlined
                dense
                v-model="form.description"
                name="short_description"
                row-height="15"
              ></v-textarea>
              <!-- <wysiwyg v-model="form.description" /> -->
             
              <v-select
                :items="status"
                class="pa-0"
                label="Status"
                outlined
                dense
                v-model="form.status"
              />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" text @click="form.reset()">Close</v-btn>
                <v-btn color="success" text v-if="save_flag" @click="validate()"
                  >Save</v-btn
                >
                <v-btn color="success" text v-else>Update</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import Mixins from "@/library/Mixins";
  import Form from "@/library/Form";
  
  export default {
    mixins: [Mixins],
    data: () => ({
      save_flag: true,
      valid: true,
      lazy: false,
     
      form: new Form(
        {
          dialog: false,
          video_link: "",
          description: "",
          status: "active",
        },
        "api/video"
      ),
      headers: [
        { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      
        {
          text: "Youtube Link",
          align: "left",
          value: "video_link",
          sortable: false,
        },
        {
          text: "Description",
          align: "left",
          value: "description",
          sortable: false,
        },
        { text: "Action", align: "right", sortable: false },
      ],
      status: [
			{
				text: "Active",
				value: "active",
			},
			{
				text: "Inactive",
				value: "inactive",
			},
	],
      footerProps: {},
      pagination: {},
    }),
    watch: {
      pagination: function () {
        this.get();
      },
    },
    methods: {
      get(params) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
        });
      },
    
      validate() {
        if (this.$refs.form.validate()) {
          this.form
            .store()
            .then((res) => {})
            .catch((err) => {});
        }
      },
    },
  };
  </script>
  